/* customized version on Material-UI CssBaseline */
html {
  scroll-behavior: smooth;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* total width */
*::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  background-color: #fff;
}
*::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */
html::-webkit-scrollbar-button {
  display: none;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

strong,
b {
  font-weight: bolder;
}

body {
  margin: 0;
  font-family: Open Sans, Helvetica, Arial, sans-serif;
  font-weight: 400;
  background-color: #fafafa;
}

body:first-child {
  margin-top: 0;
}

body::backdrop {
  background-color: #fafafa;
}

@media (min-width: 600px) {
  body {
    overflow: hidden;
    background-color: white !important;
  }
}

@media (min-width: 960px) {
  body {
    overflow: hidden;
  }
}

@media print {
  body {
    background-color: #fff;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOutRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes highlightFadeInOut {
  0% {
    background-color: transparent;
    box-shadow: 0 0 0 0 transparent;
  }
  15% {
    background-color: #e3f5fc;
    box-shadow: 0 0 3px 0 #e3f5fc;
  }
  40% {
    background-color: #e3f5fc;
    box-shadow: 0 0 3px 0 #e3f5fc;
  }
  100% {
    background-color: transparent;
    box-shadow: 0 0 0 0 transparent;
  }
}
